import { shuffleArray } from './utils';

//Regex scrub function
import { scrubHtml } from './utils';


export type Question = {
  category: string;
  correct_answer: string;
  difficulty: string;
  incorrect_answers: string[];
  question: string;
  type:  string;
}


//Combined answers to make rendering simpler
export type QuestionState = Question & { answers: string[] };


export enum Difficulty{
  //Specify my own type :)
  EASY = "easy",
  MEDIUM = "medium",
  HARD = "hard"
}
export const fetchQuizQuestions = async (amount: number, difficulty: Difficulty) => {
  const endpoint = `https://opentdb.com/api.php?amount=${amount}&difficulty=${difficulty}&category=20&type=multiple`;

  //Await HTTP request and then await the conversion to json
  const data = await (await fetch(endpoint)).json();
  return data.results.map((question: Question) => {
    const incorrect_answers = question.incorrect_answers.map(incorrect_answer => scrubHtml(incorrect_answer));
    question.question = scrubHtml(question.question);
    return({
    ...question,
    answers: shuffleArray([scrubHtml(question.correct_answer), ...incorrect_answers]),
    });
  })
}
