import React from 'react';

//Types
import { QuestionState } from '../API';
import { AnswerObject } from '../App';

//Styles
import { Wrapper } from './GameOver.styles';


type Props = {
  results: AnswerObject[];
  score: number;
}


//Prop to display final score, and questions along with the proper answers
const GameOver:React.FC<Props> = ({results, score}) => {


  return(
    <Wrapper>
      <h2>Game Over</h2>
      <h2>Your Score: {score}</h2>
      <div>
        {results.map((result, i)=> {
          return (
              <div key={i}>
                  <p>Question {i+1}: {result.question}</p>
                  <p className="answer">
                      {result.correct
                        ? <span className="correct"> {'\u2713'}</span>
                        : <span className="incorrect"> {'\u2717'}</span>}
                      Your Answer: {result.answer}
                  </p>
                  <p className="answer">
                      Correct Answer: {result.correctAnswer}
                  </p>
              </div>
          );
        })}
      </div>

    </Wrapper>

  )




}


export default GameOver;
