import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 0.75em;
  width: 90%;
  margin: 0 auto;
  padding: 0.5em;
  border-radius: 4px;
  height: 75vh;
  .correct{
    color: green;
    font-size: 1.5em;
  }
  .incorrect{
    color: red;
    font-size: 1.5em;
  }
  >div{
    text-align: left;
    padding-left: 0.25em;
    overflow-y: scroll;
    background-color: rgba(20, 20, 20, 0.8);
    max-height: 45vh;
  }

  .answer{
    text-align: center;
  }
`
