import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  width: 85vw;
  height: 75vh;
  margin: 0 auto;
  overflow-y: scroll;
  padding: 0.5em;
  border-radius: 4px;
  text-align: center;
  text-shadow: none;
  p.question, p.number{
    margin-bottom: 1em;
    color: #D9D9D9;
  }
  p.pButton{
    background-color: rgba(140, 140, 140, 0.8);
    border-radius: 2px;
    font-size: 0.75em;
    width: 10em;
    padding: 2px;
    margin: 1em auto;
    cursor: pointer;
  }
`
