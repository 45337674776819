import React from 'react';


//Types
import {AnswerObject} from '../App';


//Styles
import { Wrapper } from './QuestionCard.styles';

type Props = {
  question: string;
  answers: string[];
  callback: (event: React.MouseEvent<HTMLParagraphElement>) => void;
  userAnswer: AnswerObject | undefined;
  questionNumber: number;
  totalQuestions: number;
}
const QuestionCard:React.FC<Props> =
  ({question,
    answers,
    callback,
    userAnswer,
    questionNumber,
    totalQuestions}) =>{
  return(

    <Wrapper>
      <p className="number">
        Question: {questionNumber} / {totalQuestions}
      </p>

      <p className="question">
        {question}
      </p>
      <div>
        {answers.map(answer => {
          return (
            <p className="pButton" id={answer} onClick={callback}>{answer}</p>
          )
        })}
      </div>
    </Wrapper>


  )

}
export default QuestionCard;
