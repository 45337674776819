export const shuffleArray = (array: any[]) =>
  [...array].sort(() => Math.random() - 0.5);

export const scrubHtml = (question: string) =>{
  //Regex for pattern scrubbing
  const pattern = /&(\S)+?;/g;

  return question.replace(pattern, "");


}
