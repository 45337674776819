import React, {useState} from 'react';
import './App.css';

import {fetchQuizQuestions} from './API';



//Types
import {QuestionState, Difficulty} from './API';

//Components
import QuestionCard from './components/QuestionCard';
import GameOver from './components/GameOver';


//Styles
import {GlobalStyle} from './App.styles';




export type AnswerObject = {
  question: string;
  answer: string;
  correct: boolean;
  correctAnswer: string;
}

const TOTAL_QUESTIONS = 10;


const App = ()=>{


  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<QuestionState[]>([]);
  const [number, setNumber] = useState(0);
  const [userAnswers, setUserAnswers] = useState<AnswerObject[]>([]);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(true);
  const [difficulty, setDifficulty] = useState(Difficulty.EASY);



  const startTrivia = async () => {
    setLoading(true);
    setGameOver(false);

    const newQuestions = await fetchQuizQuestions(
      TOTAL_QUESTIONS,
      difficulty
    );

    setQuestions(newQuestions);
    setScore(0);
    setUserAnswers([]);
    setNumber(0);
    setLoading(false);

  }

  const changeDifficulty = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDifficulty(event.currentTarget.value as Difficulty);
  }

  //Typing of events...see below
  const checkAnswer = (event: React.MouseEvent<HTMLParagraphElement>) => {
    if(!gameOver){
      const answer = event.currentTarget.id;
      const correct = questions[number].correct_answer;
      if(answer === correct){
        setScore(score + 1);
      }
      //Save answer in array for user answers
      const answerObject = {
        question: questions[number].question,
        answer,
        correct: correct===answer,
        correctAnswer: correct,
      };
      setUserAnswers((prev) => [...prev, answerObject]);
      nextQuestion();
    }
  }

  const nextQuestion = () => {
    if(number + 1 === TOTAL_QUESTIONS){
      setGameOver(true);
    }else{
      setNumber(number + 1);
    }


  }
  return (
    <>
    <GlobalStyle />
    <div className="App">
      <h1>MYTHOLOGY QUIZ</h1>
      {gameOver || userAnswers.length === TOTAL_QUESTIONS ? (
        <>
          <button className="start" onClick={startTrivia}>
            Start
          </button>
          <select onChange={changeDifficulty}>
            <option value={Difficulty.EASY}>Easy</option>
            <option value={Difficulty.MEDIUM}>Medium</option>
            <option value={Difficulty.HARD}>Difficult</option>
          </select>
        </>
      ): null}

      {!gameOver ? <p className="score">Score: {score}</p> : null}
      {loading ? <p>Loading Questions ... </p> : null}

      {!loading && !gameOver ? (
        <QuestionCard
          questionNumber = {number+1}
          totalQuestions = {TOTAL_QUESTIONS}
          question={questions[number].question}
          answers={questions[number].answers}
          userAnswer={userAnswers ? userAnswers[number] : undefined}
          callback={checkAnswer} />
      ) : null }
      {gameOver && number+1 === TOTAL_QUESTIONS ? (
        <GameOver results={userAnswers} score={score}/>
      ): null }


      {!loading && !gameOver && userAnswers.length === number+1 && number !== TOTAL_QUESTIONS-1
        ? (
          <button className="next" onClick={nextQuestion}>
            Next
          </button>
      ) : null}

    </div>

    </>

  )
}

export default App;
