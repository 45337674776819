import styled, { createGlobalStyle } from 'styled-components';
import BGImage from './images/constantinos-kollias.jpeg';

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }
  body{
    background-image: url(${BGImage});
    background-size: cover;
    margin: 0;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    font-size: 1.5em;
    font-weight: 700;
    text-shadow: 1px 1px white,
                -1px -1px white,
                1px -1px white,
                -1px 1px white;
    height: 100vh;
  }

  h1{
    font-family: fantasy;
    font-size: 3.5vw;
    margin-bottom: 0;
  }

  p{
    margin: 0.5em;
  }

  button, select{
    width: 10em;
    font-size: 0.75em;
    margin: 0.25em;
  }

  *{
    box-sizing: border-box;
    font-family: monospace;
  }

`
